<template>
    <v-dialog v-model="dialog.visible" max-width="800" persistent transition="fade-transition">
        <WindowCard title="Agregar dirección de correo electrónico" actions-align="right">
            <v-label>Seleccione un empleado que tenga vinculado una dirección de correo electrónico.</v-label>
            <v-form id="form" ref="creator_form">
                <v-row>
                    <v-col cols="12">
                        <v-select solo dense outlined flat 
                            v-model="creator.empleado" 
                            :items="personas" 
                            :item-text="(i) => `${i.first_name} ${i.last_name}`"
                            return-object
                            prepend-inner-icon="account_circle" 
                            label="Seleccione un empleado..." 
                            :rules="[creation_rules.required]" />

                        <template v-if="!!creator.empleado">
                            <v-alert type="error" v-if="email_valid">El empleado seleccionado no posee una dirección de correo electrónico</v-alert>
                        </template>
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text large color="primary" @click="close()">
                    <span class="subtitle-2">Descartar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="creator_form" @click="save()">
                    <v-icon>done</v-icon>
                    <span class="ml-2 subtitle-2">Agregar</span>
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="dialog" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    components: {
        WindowCard,
        Message
    },
    data: () => ({
        personas: [],
        dialog: {
            visible: false,
            title: ''
        },
        creator: {
            empleado: null
        },
        creation_rules: {
            required: value => valid_required(value)
        }
    }),
    computed: {
        email_valid() {
            if(!this.creator.empleado) return true;

            return !this.creator.empleado.email;
        }
    },
    methods: {
        show() {
            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();

            this.dialog.visible = true;
            axiosHelper.get('personas/')
                .then(response => this.personas = response.data)
        },
        save() {
            if(!this.creator.empleado) {
                this.$refs.dialog.show("Error", "Seleccione un empleado.", "error");
                return;
            }

            if(!this.creator.empleado.email) {
                this.$refs.dialog.show("Error", "Seleccione un empleado que tenga un correo electrónico asignado.", "error");
                return;
            }

            this.$emit('success', this.creator.empleado);
            this.close();
        },
        close() {
            this.dialog.visible = false;
            this.creator.empleado = null;

            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();
        }
    }
}
</script>