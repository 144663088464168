<template>
    <v-flex class="pa-4">
        <v-switch v-model="conf.enabled" label="Habilitar envío de correos cada vez que una Solicitud de Intervención de Area caduque." />
        <v-alert type="info" color="primary" outlined>
            La plataforma notificará durante el día que caduque un SIA.
            Se notificará al <b>Responsable Inicial</b>, al <b>Superusuario</b> y a los correos asignados en el siguiente listado.
        </v-alert>
        <MailUserList v-model="conf.extras" />
    </v-flex>
</template>

<script>
import MailUserList from '@/dash/components/MailUserList'

export default {
    components: {
        MailUserList
    },
    props: ['sets'],
    computed: {
        conf() {
            return this.sets[0] || {};
        }
    },
    mounted() {
        this.value.extras = [];
    }
}
</script>