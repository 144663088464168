<template>
    <v-flex class="pa-4">
        <v-flex pb-4>
            <v-switch v-model="conf.enabled" label="Habilitar alertas por estado MP10" />
            <v-alert type="info" color="primary" outlined>
                La plataforma podrá enviar notificaciones al <b>Superusuario</b> y a los correos asignados en el siguiente listado, cuando la cantidad de Material particulado supere el valor de la 1º alerta
            </v-alert>
            <MailUserList v-model="conf.extras" />
        </v-flex>
        <v-flex pb-2>
            <v-alert type="info" color="primary" outlined>
                El proceso hará revisión de los estados de MP10 en las siguientes estaciones.
            </v-alert>
            <v-row>
                <v-col cols="12" md="5">
                    <SelectableList 
                        searchable 
                        ref="estaciones_list" 
                        search-placeholder="Buscar estación..."
                        @select-item="show_map" 
                        @unselect-item="show_map" 
                        v-model="conf.parametros" 
                        :items="list_estaciones">
                        <template v-slot:item-content="{ item }">
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </template>
                    </SelectableList>
                    <v-btn depressed large color="primary" class="subtitle-2" @click="conf.parametros = []">Vaciar selección</v-btn>
                </v-col>
                <v-col cols="12" md="7">
                    <StationsMapCard ref="mapa" v-model="list_estaciones" :height="550" @marker-click="select_station" />
                </v-col>
            </v-row>
        </v-flex>
    </v-flex>
</template>

<script>
import MailUserList from '@/dash/components/MailUserList'
import SelectableList from '@/dash/components/SelectableList'
import StationsMapCard from '@/dash/components/StationsMapCard'

export default {
    components: {
        MailUserList,
        SelectableList,
        StationsMapCard
    },
    props: ['sets', 'estaciones'],
    data: () => ({
    }),
    computed: {
        conf() {
            return this.sets[0] || {};
        },
        selected_estaciones() {
            return this.conf.parametros || [];
        },
        list_estaciones() {
            return this.estaciones || [];
        }
    },
    methods: {
        show_map(value) {
            this.$refs.mapa.set_position([value.lat, value.lon], 17);
        },
        select_station(value) {
            this.$refs.estaciones_list.toggle_item(value, false);
        }
    },
    watch: {
        conf: function(_new, _old) 
        {
            if(!this.conf.parametros) 
            {
                this.conf.parametros = [];
            }
        }
    }
}
</script>