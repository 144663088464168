<template>
    <v-flex class="pa-4">
        <v-switch v-model="nuevo.enabled" label="Habilitar notificaciones por cada Incidente Ambiental añadido." />
        <v-alert type="info" color="primary" outlined>
            La plataforma notificará por cada registro añadido al <b>Superusuario</b> y a los correos asignados en el siguiente listado.
        </v-alert>
        <MailUserList v-model="nuevo.extras" />
        <v-switch v-model="vencimiento.enabled" label="Habilitar doble notificaciones de vencimiento inminente de cada Incidente." />
        <v-alert type="info" color="primary" outlined>
            La plataforma notificará en dos ocaciones cercanas a la fecha de vencimiento de cada incidente al 
            <b>Responsable Inicial del registro</b>, al <b>Superusuario</b> y a los correos asignados en el siguiente listado.
        </v-alert>
        <MailUserList v-model="vencimiento.extras" />
    </v-flex>
</template>

<script>
import MailUserList from '@/dash/components/MailUserList'

export default {
    components: {
        MailUserList
    },
    props: ['sets'],
    computed: {
        nuevo() {
            return this.sets.filter(x => x.config_type == 3)[0] || {};
        },
        vencimiento() {
            return this.sets.filter(x => x.config_type == 2)[0] || {};
        }
    }
}
</script>