<template>
    <v-flex class="pa-4">
        <v-switch v-model="nuevo.enabled" label="Habilitar notificaciones por cada nuevo Permiso Crítico añadido." />
        <v-alert type="info" color="primary" outlined>
            La plataforma notificará cuando se añada un permiso crítico al <b>Responsable Inicial</b>, al <b>Superusuario</b> y a los correos asignados en el siguiente listado.
        </v-alert>
        <MailUserList v-model="nuevo.extras" />
        <v-switch v-model="nueva_tarea.enabled" label="Habilitar notificaciones por cada nueva tarea añadida de Permiso Crítico." />
        <v-alert type="info" color="primary" outlined>
            La plataforma notificará cada vez que una tarea sea insertada en un permiso crítico.<br/>
            Se notificará al <b>Responsable de la tarea</b>, al <b>Responsable Inicial del Permiso</b>, al <b>Superusuario</b> y a los correos asignados en el siguiente listado.
        </v-alert>
        <MailUserList v-model="nueva_tarea.extras" />
        <v-switch v-model="atraso.enabled" label="Habilitar notificaciones para tareas atrasadas por cada Permiso Crítico." />
        <v-alert type="info" color="primary" outlined>
            La plataforma notificará una lista de tareas por Permiso Crítico al Responsable de todas esas tareas.<br/>
            Se notificará al <b>Responsable de todas las tareas</b>, al <b>Responsable Inicial</b>, al <b>Superusuario</b> y a los correos asignados en el siguiente listado.
        </v-alert>
        <MailUserList v-model="atraso.extras" />
    </v-flex>
</template>

<script>
import MailUserList from '@/dash/components/MailUserList'

export default {
    components: {
        MailUserList
    },
    props: ['sets'],
    computed: {
        nuevo() {
            return this.sets.filter(x => x.config_type == 6)[0] || {};
        },
        nueva_tarea() {
            return this.sets.filter(x => x.config_type == 7)[0] || {};
        },
        atraso() {
            return this.sets.filter(x => x.config_type == 5)[0] || {};
        }
    }
}
</script>