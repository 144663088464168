<template>
    <v-card outlined>
        <v-data-table :headers="headers" :items="value" :items-per-page="-1" :mobile-breakpoint="0" hide-default-footer>
            <template v-slot:item.empleado.first_name="{ item }">
                {{ `${item.empleado.first_name} ${item.empleado.last_name}` }}
            </template>
            <template v-slot:item.aux_email="{ item }">
                <template v-if="!!item.empleado">
                    {{ item.empleado.email }}
                </template>
                <template v-else>
                    {{ item.aux_email }}
                </template>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon depressed @click="remove(item)">
                    <v-icon>close</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-divider />
        <v-card-actions>
            <v-btn depressed large color="secondary" class="subtitle-2" @click="$refs.dialog_mail.show()">
                <v-icon left>add</v-icon>
                Agregar dirección de correo
            </v-btn>
        </v-card-actions>
        <DialogMail ref="dialog_mail" @success="add" />
    </v-card>
</template>

<script>
import DialogMail from '@/dash/dialogs/DialogMail'

export default {
    // props: ['value'],
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    components: {
        DialogMail
    },
    data: () => ({
        headers: [
            { text: "Persona", value: "empleado.first_name", sortable: false },
            { text: "Correo electrónico", value: "aux_email", sortable: false },
            { text: "", value: "actions", width: "48px", sortable: false }
        ]
    }),
    methods: {
        add(empleado) {
            this.value.push({ aux_email: "", empleado: empleado });
            // this.$emit('update:myValue', { aux_email: "", empleado: empleado });
            this.$forceUpdate();
        },
        remove(item) {
            var index = this.value.indexOf(item);
            this.value.splice(this.value.indexOf(item), 1);
        }
    }
}
</script>