<template>
    <v-flex class="pa-4">
        <v-switch v-model="atraso.enabled" label="Habilitar notificaciones para tareas atrasadas por Liderazgo." />
        <v-alert type="info" color="primary" outlined>
            La plataforma notificará una lista de tareas atrasadas por cada Compromiso de seguridad que corresponda al
            <b>Responsable Inicial del registro</b>. <br/>
            Se notificará al mismo responsable, al <b>Superusuario</b> y a los correos asignados en el siguiente listado.
        </v-alert>
        <MailUserList v-model="atraso.extras" />
    </v-flex>
</template>

<script>
import MailUserList from '@/dash/components/MailUserList'

export default {
    components: {
        MailUserList
    },
    props: ['sets'],
    computed: {
        atraso() {
            return this.sets[0] || {};
        }
    }
}
</script>